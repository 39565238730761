<template>
  <div>
    <div :class="$style.wrapper">
      <a-input :value="form.product" disabled :class="$style.product" />
      <a-select
        :class="$style.type"
        show-search
        allowClear
        placeholder="文件类型"
        v-model="form.type"
        @change="handleProjectChange"
      >
        <a-select-option
          :value="item.id"
          v-for="item in types"
          :key="item.id"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </div>
    <a-table
      :columns="columns"
      :dataSource="tableList"
      :pagination="pagination"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @change="handlePagination"
    >
      <template slot="action" slot-scope="record">
        <span :class="$style.success" @click.stop="download(record)">
          下载
        </span>
        <span :class="$style.active" @click.stop="del(record)">
          删除
        </span>
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import {
  getListSoftwarePkId,
  checkFileAndVersion,
  deleteTemplate,
} from '@/services/oven/device';
import { getAuth } from '@/services/oven/file';
import { download, uuid } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
@Component()
export default class DeviceTemplate extends Vue {
  @Prop({ type: String, default: '' }) softwareId;
  @Prop({ type: String, default: '' }) type;
  @Prop({ type: Array, default: () => [] }) list;

  mounted() {
    this.form.product = this.type;
    this.getList();
  }
  async getList() {
    const params = {
      softwarePkId: this.softwareId,
      pageSize: this.pagination.pageSize,
      pageNum: this.pagination.current,
    };
    if (this.form.type) {
      params['type'] = this.form.type;
    }
    const { records, total } = await getListSoftwarePkId(params);
    this.pagination.total = total;
    this.tableList = records;
  }

  form = {
    type: undefined,
    product: '',
  };
  handleProjectChange() {
    this.getList();
  }
  types = [
    {
      id: 'MENUE',
      label: '食谱',
    },
    {
      id: 'FAVORITES',
      label: '收藏',
    },
    {
      id: 'PICTURE',
      label: '图片',
    },
    {
      id: 'SETTINGS',
      label: '设置',
    },
    {
      id: 'SERVICE',
      label: '服务',
    },
    {
      id: 'DEVELOP',
      label: '开发',
    },
    {
      id: 'PARAMETER',
      label: '参数',
    },
  ];
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        align: 'center',
        width: 60,
      },
      {
        align: 'left',
        title: '设备',
        dataIndex: 'equipmentId',
        width: 160,
      },
      {
        align: 'left',
        title: '文件名',
        dataIndex: 'fileName',
        width: 100,
      },
      {
        align: 'left',
        title: '用户',
        dataIndex: 'memberName',
        width: 70,
      },
      {
        align: 'left',
        title: '备注',
        dataIndex: 'remark',
        width: 200,
      },
      {
        align: 'left',
        title: '时间',
        dataIndex: 'updateDate',
        width: 140,
        customRender: val =>
          val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '操作',
        fixed: 'right',
        width: 120,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  tableList = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }

  selectedRowKeys = [];
  selectedList = [];
  onSelectChange(selectedRowKeys, selectedRows) {
    this.selectedRowKeys = selectedRowKeys;
    this.selectedList = selectedRows.map(v => {
      return {
        ...v,
      };
    });
  }

  async download(record) {
    if (record.fileUrl) {
      const res = await getAuth([record.fileUrl]);
      await download(res[0], record.fileName);
    }
  }
  async del(record) {
    const tip = await createFormModal(() => <div>确认删除该模板?</div>, {
      width: 500,
      title: '操作',
    });
    if (tip) {
      await deleteTemplate(record.pkId);
      this.$message.success('删除成功!');
      this.pagination.current = 1;
      this.getList();
    }
  }

  async getValue() {
    if (this.selectedList.length === 0) {
      this.$message.warn('请选择');
      return Promise.reject(false);
    }
    if (this.list.length + this.selectedList.length > 10) {
      this.$message.warn('上传超过10个');
      return Promise.reject(false);
    }
    let result = [];
    let set = new Set();
    this.list.forEach(item => {
      set.add(item.name);
    });
    for await (let item of this.selectedList) {
      const object = {
        ossPath: item.fileUrl,
        name: item.fileName,
        status: 'done',
        uid: uuid(),
      };
      if (set.has(item.fileName)) {
        continue;
      } else {
        set.add(item.fileName);
      }
      if (item.fileName.includes('.txt')) {
        const data = await checkFileAndVersion({
          filePath: item.fileUrl,
          softwareVersion: this.type,
        });
        if (!data) {
          object.status = 'error';
          object['response'] = '上传文件与当前版本不匹配';
          break;
        }
      }
      result.push(object);
    }
    if (result.length === 0) {
      this.$message.warn('已经上传过该文件，请重新选择');
      return Promise.reject(false);
    }
    return result;
  }
}
</script>
<style lang="less" module>
.wrapper {
  margin-bottom: 20px;
}
.product {
  width: 120px;
  display: inline-block;
}
.type {
  margin-left: 12px;
}
.active {
  cursor: pointer;
  margin-right: 10px;
  color: var(--primary);
}
.success {
  cursor: pointer;
  margin-right: 10px;
  color: var(--success);
}
</style>
