<template>
  <div :class="$style.box">
    <x-qrcode
      ref="qrcode"
      :class="$style.image"
      :style="qrStyle"
      :src="codeStr"
      :option="option"
    />
    <div :class="$style.label">设备码：{{ deviceItem.equipmentId }}</div>
    <div :class="$style.label">
      设备密匙：{{ secretKey === '' ? deviceItem.equipmentKey : secretKey }}
      <span :class="$style.update" @click="handleUpdated">更新</span>
    </div>
    <div :class="$style.btnGroup">
      <a-button :size="size" @click.stop="copy">复制</a-button>
      <a-button :size="size" @click.stop="download">下载</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import XQrcode from '@/components/x-qrcode';
import { clipboardWrite } from '@triascloud/utils';
import { updateKey } from '@/services/oven/device';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';

@Component({
  components: {
    XQrcode,
  },
})
export default class ShowQrcode extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceItem;
  @Prop({
    type: Object,
    default: () => {
      return {
        width: '104px',
        height: '104px',
      };
    },
  })
  qrStyle;
  size = 'small';
  option = {
    margin: 0,
  };
  secretKey = '';
  get codeStr() {
    const key =
      this.secretKey === '' ? this.deviceItem.equipmentKey : this.secretKey;
    const id = this.deviceItem.equipmentId;
    return `${id}-${key}`;
  }
  async handleUpdated() {
    const formData = new FormData();
    formData.set('equipmentPkId', this.deviceItem.pkId);
    try {
      this.secretKey = await updateKey(formData);
      this.$message.success('操作成功');
    } catch {
      this.$message.error('操作失败');
      return false;
    }
  }
  async copy() {
    const item = this.deviceItem;
    const key = this.secretKey === '' ? item.equipmentKey : this.secretKey;
    const str = `设备编号：${item.equipmentId}\n设备密钥：${key}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
  download() {
    const equipmentId = this.deviceItem.equipmentId;
    const state =
      this.deviceItem.equipmentStatus === 'BIND' ? '已绑定' : '待绑定';
    XDownloadAnchor.download(
      this.$refs.qrcode.imageSrc,
      `${equipmentId}-${state}.jpg`,
    );
  }
}
</script>
<style lang="less" module>
.box {
  text-align: center;
  font-size: 12px;
  .image {
    margin: 0 auto 20px;
    display: inherit;
  }
  .label {
    text-align: left;
  }
  .update {
    color: var(--primary);
    cursor: pointer;
  }
  .btnGroup {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
