<template>
  <div :class="$style.detail">
    <div :class="$style.info">
      <a-row :gutter="20" :class="$style.infoItem">
        <a-col v-if="currentBind !== null" :span="12">
          <span :class="$style.name">当前绑定：</span>
          <span>{{ currentBind.memberName }}</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ currentBind.mobilePhone }}</span>
              <span
                style="color: var(--primary);margin-left: 10px; cursor: pointer"
                @click="handleCopy(currentBind)"
              >
                点击复制
              </span>
            </template>
            <x-icon :class="$style.iconStyle" type="tc-icon-dianhua1" />
          </a-tooltip>
        </a-col>
        <a-col :span="12" v-if="hideDeviceCode">
          <span :class="$style.name">设备编码：</span>
          <span>{{ deviceCode }}</span>
        </a-col>
      </a-row>
      <a-row :gutter="20" :class="$style.infoItem">
        <a-col :span="12">
          <span :class="$style.name">工厂门店负责人：</span>
          <span v-if="shopManager !== null">
            {{ shopManager.memberName }}
          </span>
          <a-tooltip v-if="shopManager !== null" placement="top">
            <template slot="title">
              <span>{{ shopManager.mobilePhone }}</span>
              <span
                style="color: var(--primary);margin-left: 10px; cursor: pointer"
                @click="handleCopy(shopManager)"
              >
                点击复制
              </span>
            </template>
            <x-icon :class="$style.iconStyle" type="tc-icon-dianhua1" />
          </a-tooltip>
        </a-col>
        <a-col :span="12">
          <span :class="$style.name">负责工程师：</span>
          <span v-for="(item, idx) in engineer" :key="idx">
            <span>
              {{ item.memberName }}
            </span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ item.mobilePhone }}</span>
                <span
                  style="color: var(--primary);margin-left: 10px; cursor: pointer"
                  @click="handleCopy(item)"
                >
                  点击复制
                </span>
              </template>
              <x-icon :class="$style.iconStyle" type="tc-icon-dianhua1" />
            </a-tooltip>
          </span>
        </a-col>
      </a-row>
      <a-row :gutter="20" :class="$style.infoItem">
        <a-col :span="12">
          <span :class="$style.name">最近操作</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>最近一次有提交修改配置参数的时间</span>
            </template>
            <a-icon :class="$style.timeIcon" type="question-circle-o" />
          </a-tooltip>
          <span>：{{ time }}</span>
        </a-col>
      </a-row>
    </div>
    <div :class="$style.contentBox">
      <a-tabs default-active-key="activeTab" @tabClick="tabClick">
        <a-tab-pane key="1" tab="食谱设置">
          <DetailBackup
            v-if="activeTab === '1'"
            :unitData="unitData"
            backup-type="RECIPE_SETTINGS"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="收藏管理">
          <DetailBackup
            v-if="activeTab === '2'"
            :unitData="unitData"
            backup-type="COLLECT_MANAGE"
          />
        </a-tab-pane>
        <a-tab-pane key="3" tab="其他">
          <DetailBackup
            v-if="activeTab === '3'"
            :unitData="unitData"
            backup-type="CUSTOMIZE"
          />
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          当前层序号
          <a-select
            style="width: 100px"
            :value="form.layer"
            placeholder="序号"
            @change="handleLayerChange"
          >
            <a-select-option
              v-for="item in layerList"
              :key="item.pkId"
              :value="item.pkId"
            >
              {{ item.layerNumber }}
            </a-select-option>
          </a-select>
        </div>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoItem from '@/views/iot/camera/components/info-item.vue';
import DetailBackup from './detail-backup.vue';
import { getDeviceDetail } from '@/services/oven/device';
import dayjs from 'dayjs';
import { clipboardWrite } from '@triascloud/utils';

@Component({
  components: {
    InfoItem,
    DetailBackup,
  },
})
export default class Detail extends Vue {
  @Prop({ type: String, default: '' }) equipmentId;

  form = {
    layer: undefined,
  };
  unitData = {};
  handleLayerChange(layer) {
    this.form.layer = layer;
    const idx = this.layerList.findIndex(v => v.pkId === layer);
    this.unitData = this.layerList[idx];
  }

  mounted() {
    this.getDetailList();
  }
  activeTab = '1';
  tabClick(v) {
    this.activeTab = v;
  }
  currentBind = {};
  deviceCode = '';
  hideDeviceCode = false;
  shopManager = {};
  engineer = [];
  time = '';
  layerList = [];
  async getDetailList() {
    const id = this.equipmentId;
    try {
      const result = await getDeviceDetail(id);
      if (result.kolbUnitInEquipmentUnits.length > 0) {
        this.layerList = result.kolbUnitInEquipmentUnits;
        this.form.layer = this.layerList[0].pkId;
        this.unitData = this.layerList[0];
        this.currentBind = result.bindMember;
        this.deviceCode = result.equipmentNumber;
        this.hideDeviceCode = result.equipmentNumber !== '';
        this.shopManager = result.headOfFactoryStore || null;
        this.engineer =
          result.engineerMemberList > 3
            ? result.engineerMemberList.slice(0, 3)
            : result.engineerMemberList;
        this.time = result.operationDate
          ? dayjs(result.operationDate).format('YYYY/MM/DD HH:mm')
          : '';
      }
    } catch {
      return false;
    }
  }
  async handleCopy(item) {
    const str = `姓名：${item.bindMember.memberName}\n手机号码：${item.bindMember.mobilePhone}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
}
</script>
<style lang="less" module>
.info {
  margin: 20px;
  padding: 0 20px;
  border-radius: 6px;
  box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);

  .infoItem {
    color: var(--font);
    padding-top: 15px;
    &:last-child {
      padding: 15px 0;
    }
  }
  .name {
    color: var(--font-info);
  }
}
.iconStyle {
  cursor: pointer;
  color: var(--primary);
  margin-left: 10px;
}
.timeIcon {
  color: var(--font-info);
  margin-left: 5px;
}
.contentBox {
  margin: 20px;
  border-radius: 6px;
  :global {
    .ant-tabs-bar {
      margin: 0 0 16px;
      border-bottom: 1px solid #e8e8e8;
      outline: initial none;
    }
  }
}
</style>
