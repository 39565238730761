<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item prop="type">
      <span slot="label">
        <span>软件版本</span>
        <span v-if="showTxt !== ''">
          {{ showTxt }}
        </span>
      </span>
      <a-select
        show-search
        allowClear
        :labelInValue="true"
        placeholder="请选择软件版本"
        v-model="form.type"
        @change="handleProjectChange"
      >
        <a-select-option
          :value="item.id"
          v-for="item in types"
          :key="item.id"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      v-if="form.type !== undefined"
      ref="uploadRef"
      label="上传文件"
      prop="upload"
    >
      <a-upload
        :fileList="form.upload"
        :customRequest="customUpload"
        :beforeUpload="beforeUpload"
        @change="fileChange"
        accept=".txt,.bmp,.bin"
      >
        <section>
          <a-button v-if="form.upload.length < 10">
            <a-icon type="upload" />
            选择文件
          </a-button>
          <a-button
            v-if="form.upload.length < 10"
            @click.stop="selectFileFromTemplate"
            :class="$style.template"
          >
            <a-icon type="upload" />
            从模板中选择
          </a-button>
        </section>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item label="更新说明" prop="updateCaption">
      <a-textarea
        :row="4"
        :maxLength="100"
        placeholder="请输入更新说明"
        v-model="form.updateCaption"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getVersionList,
  batchUpdateDevice,
  checkFileAndVersion,
} from '@/services/oven/device';
import { UploadService } from '@triascloud/services';
import { createFormModal } from '@triascloud/x-components';
import DeviceTemplate from './device-template.vue';

@Component({})
export default class BatchUpdate extends Vue {
  @Prop({ type: Array, default: () => [] }) idList;
  @Prop({ type: String, default: '' }) pkIds;
  form = {
    type: undefined,
    upload: [],
    updateCaption: '',
  };
  types = [];
  rules = {
    type: [
      {
        required: true,
        message: '软件版本不能为空',
      },
    ],
    upload: [
      {
        type: 'array',
        required: true,
        message: '请至少上传一个文件',
      },
    ],
  };
  customUpload() {}
  beforeUpload(file) {
    return new Promise((resolve, reject) => {
      if (
        file.name.includes('.txt') ||
        file.name.includes('.bin') ||
        file.name.includes('.bmp')
      ) {
        const sizeValidate = file.size / 1024 / 1024 > 50;
        if (sizeValidate) {
          this.$message.error('上传单个文件为大于50M');
          reject(false);
        }
        if (this.form.upload.length > 0) {
          const arr = this.form.upload.map(v => v.name);
          if (arr.indexOf(file.name) > -1) {
            this.$message.error('已经上传过该文件，请重新选择');
            reject(false);
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      } else {
        this.$message.warn('上传的文件非txt/bmp/bin类型');
        reject(false);
      }
    });
  }
  async uploadFile(file) {
    const files = file.originFileObj;
    const uploadPath = `${this.pkIds}/kolb/files`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const ossUrl = await customService.upload(
      files,
      uploadPath,
      () => {},
      false,
    );
    file.ossPath = ossUrl;
    const params = {
      filePath: ossUrl,
      softwareVersion: this.form.type.label,
    };
    try {
      if (file.name.includes('.txt')) {
        const data = await checkFileAndVersion(params);
        if (data) {
          file.status = 'done';
          this.fileLists.push(file);
          return ossUrl;
        } else {
          file.status = 'error';
          file.response = '上传文件与当前版本不匹配';
        }
      } else {
        file.status = 'done';
        this.fileLists.push(file);
      }
    } catch {
      return false;
    }
  }
  fileLists = [];
  async fileChange({ fileList }) {
    if (fileList.length > this.form.upload.length) {
      let file = fileList.pop();
      fileList.push(file);
      this.form.upload = fileList;
      await this.uploadFile(file);
    } else {
      this.form.upload = fileList;
      this.form.upload.forEach((i, index) => {
        const flag = fileList.findIndex(v => v.uid === i.uid);
        if (flag === -1) {
          this.fileLists.splice(index, 1);
        }
      });
    }
  }
  versionList = [];
  mounted() {
    this.getVersionLists();
  }
  async getVersionLists() {
    try {
      const result = await getVersionList(this.idList);
      this.versionList = result;
      this.types = result.map(v => {
        return {
          id: v.pkId,
          label: v.softwareVersion,
        };
      });
    } catch {
      return false;
    }
  }
  showTxt = '';
  pkId = '';
  deviceId = [];
  handleProjectChange(obj) {
    if (obj !== undefined) {
      if (obj.key !== this.pkId) {
        this.form.upload = [];
      }
      const idx = this.versionList.findIndex(v => v.pkId === obj.key);
      const item = this.versionList[idx];
      this.showTxt = `（共选择${item.equipmentCount}台设备，当前版本包含${item.unitCount}层控制单元）`;
      this.pkId = obj.key;
      this.deviceId = item.equipmentPkIdList;
    } else {
      this.showTxt = '';
      this.pkId = '';
      this.deviceId = '';
      this.form.upload = [];
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = {
            equipmentIdList: this.deviceId,
            idxSoftwareId: this.pkId,
            updateCaption: this.form.updateCaption,
            fileNameAndPathList: {},
          };
          if (this.form.upload.length > 0) {
            const idx = this.form.upload.findIndex(v => v.status === 'error');
            if (idx > -1) {
              this.$message.error('上传文件与当前版本不匹配，请重新上传');
              reject(false);
            } else {
              this.fileLists.forEach(v => {
                params.fileNameAndPathList[v.name] = v.ossPath;
              });
              try {
                await batchUpdateDevice(params);
                this.$message.success('批量更新成功');
                resolve(true);
              } catch {
                reject(false);
                return false;
              }
            }
          }
        } else {
          reject(false);
        }
      });
    });
  }

  async selectFileFromTemplate() {
    if (!this.form.type) {
      this.$message.warn('请选择软件版本');
      return false;
    }
    const result = await createFormModal(
      () => (
        <DeviceTemplate
          softwareId={this.form.type.key}
          list={this.form.upload}
          type={this.form.type.label}
        />
      ),
      {
        title: '模板',
        width: 1200,
        footer: (h, submit) => [
          <a-button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            选中
          </a-button>,
        ],
      },
    );
    this.form.upload = this.form.upload.concat(result);
    this.fileLists = this.fileLists.concat(result);
  }
}
</script>
<style lang="less" module>
.template {
  margin-left: 20px;
}
</style>
