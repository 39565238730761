<template>
  <div :class="$style.table">
    <a-table :columns="columns" :dataSource="list" :pagination="false">
      <template slot="ipTitle">
        当前ip
        <a-tooltip placement="top">
          <template slot="title">
            <span>括号内是出厂时设置的“设备/层IP”</span>
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
      </template>
      <template slot="ipTitle" slot-scope="text">
        <span>{{ text }}</span>
      </template>
      <template slot="domainTitle">
        外网域名
        <a-tooltip placement="top">
          <template slot="title">
            <span>括号内是出厂预设数值，若无法使用请联系厂家！</span>
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
      </template>
      <template slot="statusTitle">
        状态
        <a-tooltip placement="top">
          <template slot="title">
            <span>禁用后将无法访问管理此层设备！</span>
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
      </template>
      <template slot="statusSlot" slot-scope="text, record, index">
        <a-switch
          :checked="text"
          @change="input => switchChange(input, record, index)"
        />
      </template>
    </a-table>
    <div style="text-align: center;margin-top: 10px;">
      <a-button @click="close">
        关闭
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { getLayerList, undateLayerStatus } from '@/services/oven/device';
import { deepClone } from '@triascloud/utils';

@Component({})
export default class ShowLayer extends Vue {
  @Prop({ type: String, default: '' }) pkId;
  get columns() {
    return [
      {
        dataIndex: 'layerNumber',
        title: '层序号',
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        title: '产品分类',
        dataIndex: 'productType',
        ellipsis: true,
      },
      {
        title: '软件版本',
        dataIndex: 'softwareVersion',
      },
      {
        dataIndex: 'ipTitle',
        slots: { title: 'ipTitle' },
        ellipsis: true,
        scopedSlots: { customRender: 'ipTitle' },
      },
      {
        dataIndex: 'domainTitle',
        ellipsis: true,
        slots: { title: 'domainTitle' },
      },
      {
        dataIndex: 'status',
        slots: { title: 'statusTitle' },
        scopedSlots: { customRender: 'statusSlot' },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        ellipsis: true,
      },
    ];
  }
  list = [];
  mounted() {
    this.getTableList();
  }
  defaultList = [];
  async getTableList() {
    const id = this.pkId;
    try {
      const result = await getLayerList(id);
      this.defaultList = deepClone(result);
      this.list = result.map(v => {
        return {
          ...v,
          ipTitle: v.equipmentOrLayersIpSelf
            ? `${v.equipmentOrLayersIpSelf}（${v.equipmentOrLayersIp}）`
            : v.equipmentOrLayersIp,
          domainTitle:
            v.domainNameSelf || v.domainName
              ? v.domainNameSelf || v.domainName
              : v.domainNameSelf && v.domainName
              ? `${v.domainNameSelf}（${v.domainName}）`
              : '',
        };
      });
    } catch {
      return false;
    }
  }

  async switchChange(v, row) {
    const formData = new FormData();
    formData.set('enable', v);
    formData.set('unitPkId', row.pkId);
    try {
      await undateLayerStatus(formData);
      this.$message.success('更改成功');
      await this.getTableList();
    } catch {
      await this.getTableList();
      return false;
    }
  }

  @Emit('close')
  close() {}
}
</script>
<style lang="less" module>
.table {
}
</style>
