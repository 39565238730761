<template>
  <div :class="$style.table">
    <div :class="$style.label">
      <span>
        <span>*</span>
        注意：只记录最近一次绑定以后的数据！
      </span>
      <span>
        <span>{{ showData.productType }}</span>
        <span style="margin-left: 10px">
          {{ showData.productVersion }}
        </span>
      </span>
    </div>
    <a-table :columns="columns" :dataSource="tableList" :scroll="{ y: 600 }">
      <template slot="action" slot-scope="record">
        <x-icon
          @click="handleDownload(record)"
          type="tc-icon-text"
          :class="$style.icon"
        />
        <span @click="handleDownload(record)" :class="$style.downloadText">
          下载
        </span>
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getBackup } from '@/services/oven/device';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';
import dayjs from 'dayjs';
@Component({})
export default class DetailBackup extends Vue {
  @Prop({ type: Object, default: () => {} }) unitData;
  @Prop({ type: String, default: '' }) backupType;

  @Watch('unitData', { immediate: true })
  changeData(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.initData(newVal);
    }
  }
  showData = {
    productType: '',
    productVersion: '',
  };
  async initData(row) {
    if (row.pkId) {
      const list = row;
      this.showData.productType = `产品分类：${list.productType}`;
      this.showData.productVersion = `软件版本：${list.softwareVersion}`;
      const params = {
        unitPkId: row.pkId,
        backupType: this.backupType,
      };
      try {
        const res = await getBackup(params);
        this.tableList = res.map(v => {
          return {
            ...v,
            createDate:
              v.createDate !== ''
                ? dayjs(v.createDate).format('YYYY-MM-DD HH:mm:ss')
                : '',
            person: v.operator?.memberName,
          };
        });
        this.pagination.total = res.length;
      } catch {
        return false;
      }
    }
  }
  tableList = [];
  get columns() {
    let arr1 = [
      {
        title: '操作时间',
        dataIndex: 'createDate',
      },
      {
        title: '操作人',
        dataIndex: 'person',
      },
      {
        title: '提交状态',
        dataIndex: 'commitStatus',
        customRender: value => (value ? '成功' : '失败'),
      },
      {
        title: '下载',
        scopedSlots: { customRender: 'action' },
      },
    ];
    if (this.backupType === 'CUSTOMIZE') {
      const o = {
        title: '更新文件',
        dataIndex: 'fileName',
      };
      arr1.splice(3, 0, o);
    }
    return arr1;
  }
  handleDownload(row) {
    const name = row.fileName.split('.');
    const time =
      row.createDate !== ''
        ? dayjs(row.createDate).format('YYYYMMDDHHmmss')
        : '';
    const url = row.fileUrl;
    const fileName = `${name[0]}${time}.${name[1]}`;
    XDownloadAnchor.download(url, fileName);
  }
}
</script>
<style lang="less" module>
.table {
  .label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
  }
  .icon {
    font-size: 25px;
    color: var(--primary);
    vertical-align: inherit;
    cursor: pointer;
  }
  .downloadText {
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
