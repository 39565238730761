<template>
  <div :class="$style['info-item']">
    <span :class="$style.name">
      {{ name }}
    </span>
    <span :class="$style.value">{{ value }}</span>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InfoItem extends Vue {
  @Prop({ type: String, default: '' }) name;
  @Prop({ type: String, default: '' }) value;
}
</script>

<style lang="less" module>
.info-item {
  font-size: 15px;
  color: var(--font);
  width: 100%;
  flex: 1;
  display: flex;
  .name {
    color: var(--font-info);
    flex-shrink: 0;
  }
  .value {
    flex: 1;
    word-break: break-all;
  }
}
</style>
